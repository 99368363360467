<template>
  <div class="show-picture">
    <div class="carousel-wrap">
      <template v-if="images && images.length > 0">
        <el-carousel @change="onCarouselChange"
                     indicator-position="outside">
          <el-carousel-item
            v-for="item in images" :key="item"
          >
            <el-image
              @click="onPreview"
              title="点击查看大图"
              :src="item" fit="contain"
              style="width: 100%; height: 100%"
            ></el-image>
          </el-carousel-item>
        </el-carousel>
      </template>
    </div>
    <el-image-viewer
      v-if="showViewer"
      :initial-index="index"
      :on-close="closeViewer"
      :url-list="images"/>
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

let prevOverflow = '';
export default {
  name: "ShowPicture",
  components: {
    ElImageViewer,
  },
  props: {
    images: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      showViewer: false,
      index: 0,
    }
  },
  methods: {
    onCarouselChange(val, old) {
      this.index = val;
    },
    onPreview() {
      prevOverflow = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
      this.showViewer = true
    },
    closeViewer() {
      document.body.style.overflow = prevOverflow;
      this.showViewer = false;
    }
  }
}
</script>

<style scoped lang="scss">
.show-picture {
  /deep/ {
    .carousel-wrap {
      img {
        cursor: pointer;
      }
    }
  }
}
</style>
